import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import "@fontsource/amatic-sc"
import { Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    background: 'url(./topimage.jpg) no-repeat center center',
    backgroundSize: 'contain',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '200px 0px 100px 0px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '20px',
      padding: '250px 0px 300px 0px',
    },
  },
  title: {
    color: theme.palette.grey[50],
  },
  menuLink: {
    fontSize: '1.5em',
    '&:hover':{
      color: theme.palette.secondary.main
    }
  }
}));

const  Top = () => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.container} >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h2" className={classes.title}>
              Anfang
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
          <Grid item>
            <Typography variant="body1">
                  イラストレーターめるろってのホームページです。<br/>
                  ご依頼については<Link href='/request' underline="none">request</Link>をご参照ください。<br/>
                  ごゆっくりご鑑賞ください。<br/>
            </Typography>
          </Grid>
          <Grid item>
          <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={5}
            >
              <Grid item>
                <Link href='/profile' className={classes.menuLink} underline="none">profile</Link>
              </Grid>
              <Grid item>
                <Link href='/illustration' className={classes.menuLink} underline="none">illusration</Link>
              </Grid>
              <Grid item>
                <Link href='/request' className={classes.menuLink} underline="none">request</Link>
            </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Top;
